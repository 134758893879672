import { Route, Switch, Redirect } from 'react-router-dom';
import { DFduckLayout } from './modules/layout/components/DFduckLayout';
import { PageNotFound } from './modules/router/components/PageNotFound';
import { PageComingSoon } from './modules/router/components/PageComingSoon';
import { Themes } from './modules/themes/types';
import { DFdunkRoutes, DFdunkRoutesConfig } from './modules/DFdunkHome/Routes';
import { DFdunkHistoryRoutesConfig, DFdunkHistoryRoutes } from './modules/DFdunkHistory/Routes';
import { DFdunkDropsRoutesConfig, DFdunkDropsRoutes } from './modules/DFdunkDrops/Routes';
import { DFdunkSwapRoutes, DFdunkSwapRoutesConfig } from './modules/DFdunkSwap/Routes';
import { DFdunkHistoryDetailRoutesConfig, DFdunkHistoryDetailRoutes } from './modules/DFdunkHistoryDetail/Routes';
import { DFdunkMintRoutes, DFdunkMintRoutesConfig } from './modules/DFdunkMint/Routes';

export function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={DFdunkRoutesConfig.Index.path} />}
      />

      <Route
        exact
        path={DFdunkRoutesConfig.Index.path}
        render={() => (
          <DFduckLayout headerTheme={Themes.light}>
            <DFdunkRoutes />
          </DFduckLayout>
        )}
      />

      <Route
        exact
        path={DFdunkHistoryRoutesConfig.Index.path}
        render={() => (
          <DFduckLayout headerTheme={Themes.light}>
            <DFdunkHistoryRoutes />
          </DFduckLayout>
        )}
      />

      <Route
        exact
        path={DFdunkHistoryDetailRoutesConfig.Index.path}
        render={() => (
          <DFduckLayout headerTheme={Themes.light}>
            <DFdunkHistoryDetailRoutes />
          </DFduckLayout>
        )}
      />

      <Route
        exact
        path={DFdunkDropsRoutesConfig.Index.path}
        render={() => (
          <DFduckLayout headerTheme={Themes.light}>
            <DFdunkDropsRoutes />
          </DFduckLayout>
        )}
      />
      <Route
        exact
        path={DFdunkSwapRoutesConfig.Index.path}
        render={() => (
          <DFduckLayout headerTheme={Themes.light}>
            <DFdunkSwapRoutes />
          </DFduckLayout>
        )}
      />
      <Route
        exact
        path={DFdunkMintRoutesConfig.Index.path}
        render={() => (
          <DFduckLayout headerTheme={Themes.light}>
            <DFdunkMintRoutes />
          </DFduckLayout>
        )}
      />

      <Route
        exact
        path={'/dfdunk/coming_soon'}
        render={() => (
          <DFduckLayout headerTheme={Themes.light}>
            <PageComingSoon />
          </DFduckLayout>
        )}
      />

      <Route
        render={() => (
          <DFduckLayout headerTheme={Themes.light}>
            <PageNotFound />
          </DFduckLayout>
        )}
      />
    </Switch>
  );
}
