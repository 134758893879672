import { fade, makeStyles, Theme } from '@material-ui/core';


export const HEADER_HEIGHT_XS = 90;
export const HEADER_HEIGHT_XL = 90;

export const useHeaderStyles = makeStyles<Theme>(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.25, 0),
    // borderBottom: `1px solid ${fade(theme.palette.text.primary, 0.15)}`,
    background: '#000',
    color: theme.palette.text.primary,
    height: HEADER_HEIGHT_XS,
    zIndex: 2,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2, 0),
      height: HEADER_HEIGHT_XL,
    },
  },
  overViewHead: {
    zIndex: 2,
    background: fade(theme.palette.background.default, 0.1),
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.background.default,
    },
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0, 2),
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    position: 'relative',
    paddingLeft: theme.spacing(13.5),
    paddingRight: theme.spacing(13.5),
    [theme.breakpoints.up('xl')]: {
      // gridTemplateColumns: 'auto auto 1fr repeat(2, auto)',
      // justifyContent: 'initial',
      paddingLeft: theme.spacing(13.5),
      paddingRight: theme.spacing(13.5),
    },
    [theme.breakpoints.down('md')]: {
      // gridTemplateColumns: 'auto auto 1fr repeat(2, auto)',
      // justifyContent: 'initial',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    // [theme.breakpoints.up('WXGAPlus')]: {
    //   paddingLeft: theme.spacing(5.5),
    //   paddingRight: theme.spacing(5.5),
    // },
  },

  search: {
    [theme.breakpoints.up('xl')]: {
      width: 340,
    },
  },

  searchMobile: {
    Width: '100%',
  },

  searchBox: {
    position: 'absolute',
    zIndex: 10,
    top: 0,
    left: 0,

    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',

    background: theme.palette.background.default,
  },

  searchBoxContainer: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(0, 2),
  },

  btnCreate: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(2),
    },
  },

  buttons: {
    display: 'grid',
    alignItems: 'center',
    gap: theme.spacing(0, 2),
    gridTemplateColumns: 'auto auto',

    '& i': {
      color: '#fff',
    }
  },

  drawer: {
    top: `${HEADER_HEIGHT_XS}px !important`,
  },

  drawerPaper: {
    position: 'absolute',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.7)',
    color: theme.palette.common.white,
    maxWidth: 410,
    overflow: 'hidden',
  },

  drawerBackdrop: {
    position: 'absolute',
  },

  navInner: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),

  },

  btnChangeNet: {
    marginRight: 18,
    height: '48px',
    fontSize: '16px',
    color: '#fff',
  },
  nav: {
    display: 'flex',
    justifyContent: 'end'
  },
  connect: {
    height: '28px',
    fontSize: '12px',
    color: '#fff',
    padding: '8px 16px',
    fontWeight: 400,
    minWidth: '78px',
    marginLeft: '8px',
  },
  connectBorder: {
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(24px)',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: fade('#8468FF', 0.8),
    },
  },
  connectMobile: {
    height: '28px',
    fontSize: '12px',
    color: '#fff',
    padding: '8px 16px',
    fontWeight: 400,
    width: '78px',
    marginLeft: '0',
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(24px)',
    borderRadius: '4px',
    border: '1px solid #fff',
    marginTop: '24px',
    '&:hover': {
      backgroundColor: fade('#8468FF', 0.8),
    },
  },
  btnMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    marginBottom: '20px'
  },
  headerImg: {
    position: 'absolute',
    top: '20px',
    right: '0',
    width: '40%'
  },
  loading: {
    animationName: '$spin',
    animationDuration: '1s',
    animationDelay: '0s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    willChange: 'transform',
    marginLeft: '10px',
  },
  '@keyframes spin': {
    '100%': { transform: 'rotate(360deg)' },
  },
  pending: {
    marginLeft: '6px'
  },
  navs: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  links: {
    marginRight: '20px'
  },
  newsearchBox: {
    width: '250px',
    height: '40px',
    borderRadius: '20px',
    border: '1px solid #EAECEF',
    position: 'relative',
    overflow: 'hidden'
  },
  hearderSearch: {
    height: '38px',
    fontSize: '16px',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '200px',
    border: 'none',
    outline: 'none'
  },
  searchIcon: {
    position: 'absolute',
    top: '8px',
    left: '20px',
  },
  img: {
    height: '70px'
  },
  centerLogo: {
    position: 'absolute',
    left: '50%',
    marginLeft: '-35px',
    top: '50%',
    marginTop: '-35px'
  },
  link: {
    fontWeight: 700,
    fontSize: '16px',
    color: '#fff',
    minWidth: '80px',
    fontFamily: 'Pretendard,"Roboto Condensed"',
    '&:hover': {
      opacity: '0.6',
      color: '#fff',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      minWidth: '50px',
      marginLeft: '6px !important'
    },
    [theme.breakpoints.down('sm')]: {
      borderBottom: '2px solid rgba(255, 255, 255, 0.2)',
      width: '100%',
      borderRadius: 0,
      fontSize: '16px',
      justifyContent: 'flex-start',
      paddingLeft: '0px !important'
    },
  },
  connected: {
    border: '2px solid #FFFFFF',
  },
  activeLink: {
    opacity: '0.6',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      color: '#325EFF',
      opacity: '1',
    },
  },
  idadaki: {
    color: '#fff',
    marginLeft: '6px',
    fontWeight: 800,
    fontSize: '18px'
  }
}));
