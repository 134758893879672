import { Box, BoxProps } from '@material-ui/core';
import { LoadingProps } from '@redux-requests/react';
import React from 'react';
import { Spinner } from '../Spinner';

interface IQueryLoadingProps extends LoadingProps {
  size?: number;
}

export const QueryLoading = ({ size }: IQueryLoadingProps) => {
  return <Spinner size={size} />;
};

export const QueryLoadingAbsolute = () => {
  return <Spinner centered={true} />;
};

export const QueryLoadingCentered = (props: BoxProps) => {
  return (
    <Box display="flex" justifyContent="center" {...props}>
      <Spinner />
    </Box>
  );
};

export const WhiteQueryLoadingAbsolute = (props: BoxProps) => {
  return (
    <Box style={{position:'fixed',top:0,left:0,bottom:0,right:0,zIndex:999,backgroundColor:'rgba(0,0,0,0.4)'}} {...props}>
      <Box display="flex"  style={{width:'100%',height:'100%'}} justifyContent="center" color="#fff">
        <Spinner centered />
      </Box>
    </Box>
  );
};
