import { makeStyles, Theme } from '@material-ui/core';

export const FOOTER_MIN_HEIGHT = 64;

export const useFooterStyles = makeStyles<Theme>(theme => ({
  root: {
    background: 'rgb(30,30,35)',
    paddingTop: '45px',
    paddingBottom: '50px',
    borderTop: '2px solid #A7AEBB',
    [theme.breakpoints.up('md')]: {
      minHeight: FOOTER_MIN_HEIGHT,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '24px',
    },
    color: '#A7AEBB'
  },

  container: {
    width: '1200px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  copyright: {
    textAlign: 'left',
    '& p': {
      fontSize: 12,
      lineHeight: '16px',
      marginBottom: '5px',
    },
    '& span': {
      marginLeft: '4px',
    }
  },
  terms: {
    display: 'flex',
  },
  imgList: {
    display: 'flex',
    alignItems: 'flex-start',
    '& img': {
      width: '50px',
      marginLeft: '20px',
    }
  },
  links: {
    textAlign: 'right',
    '& a': {
      marginLeft: '26px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
      color: '#A7AEBB',
      '&:hover': {
        color: '#A7AEBB',
        opacity: '0.6'
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '10px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  idadaki: {
    // color: '#fff',
    // marginLeft: '6px',
    fontWeight: 800,
    fontSize: '18px !important'
  }
}));
