const ONE_DAY = 1000 * 60 * 60 * 24;

export const getDaysLeft = (endDate: Date) => {
  const today = new Date();

  if (today.getMonth() === 11 && today.getDate() > 25) {
    endDate.setFullYear(endDate.getFullYear() + 1);
  }

  return Math.ceil((endDate.getTime() - today.getTime()) / ONE_DAY);
};

export function getTimeRemaining(endtime: Date) {
  const total =
    Date.parse(new Date(endtime).toString()) -
    Date.parse(new Date().toString());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}

export function getTimeLeft(timers: number) {
  const total =
    Date.parse(new Date(timers).toString()) -
    Date.parse(new Date().toString());
  const oneDay = 1000 * 60 * 60 * 24, oneHour = 1000 * 60 * 60, oneMinute = 1000 * 60;
  let days = Math.floor(total / oneDay).toString();
  let hours = Math.floor(((total - parseInt(days) * oneDay) / oneHour)).toString();
  let minutes = Math.floor(((total - parseInt(days) * oneDay - parseInt(hours) * oneHour) / oneMinute)).toString();
  let seconds = Math.floor((total - parseInt(days) * oneDay - parseInt(hours) * oneHour - parseInt(minutes) * oneMinute) / 1000).toString();
  days = parseInt(days) > 10 ? days : '0' + days;
  hours = parseInt(hours) > 10 ? hours : '0' + hours;
  minutes = parseInt(minutes) > 10 ? minutes : '0' + minutes;
  seconds = parseInt(seconds) > 10 ? seconds : '0' + seconds;
  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}

export function getTimeFormat(timers: number) {
  const years = new Date(timers).getFullYear();
  const mounth = new Date(timers).getMonth() + 1;
  const days = new Date(timers).getDate();
  const formatDate = years + '.' + mounth + '.' + days
  return formatDate;
}
