import { Container } from '@material-ui/core';
import { useFooterStyles } from './FooterStyles';
import { Typography, Box } from '@material-ui/core';
import logo from '../DFduckHeader/assets/logos.svg';
import { useQuery } from '@redux-requests/react';
import { getDfdunkSns, IDfdunkSnsData, getDfdunkLogo, IDfdunkLogoData } from 'modules/common/actions/commonRequest';
import { useIsSMDown } from 'modules/themes/useTheme';
import { useLocale } from 'modules/i18n/utils/useLocale';

export const Footer = () => {
  const { data } = useQuery<IDfdunkSnsData | null>({
    type: getDfdunkSns.toString(),
  });
  const { data: logoData } = useQuery<IDfdunkLogoData | null>({
    type: getDfdunkLogo.toString(),
  });
  const isMobile = useIsSMDown();
  const classes = useFooterStyles();
  const { locale } = useLocale();
  return (
    <footer className={classes.root}>
      {isMobile ? <Box className={classes.mobile}>
        <Box className={classes.imgList} display={'flex'} justifyContent={'center'}>
          <img src={logoData ? logoData.logo : logo} alt="" />
        </Box>
        <Box mt={2}>
          <Typography className={classes.links}>
            <a href="/">Terms</a>
            <a href="/">Privacy</a>
            <a href="/">Status</a>
            <a href="/">Help</a>
            <a href="/">Press</a>
            <a href="/">Blog</a>
            <a href="/">Sign Up</a>
          </Typography>
          <Typography className={classes.links}>
            {data?.discord && <a href={data.discord} rel="noreferrer" target={'_blank'}>Discord</a>}
            {data?.twitter && <a href={data.twitter} rel="noreferrer" target={'_blank'}>Twitter</a>}
            {data?.instagram && <a href={data.instagram} rel="noreferrer" target={'_blank'}>Instagram</a>}
            {data?.facebook && <a href={data.facebook} rel="noreferrer" target={'_blank'}>Facebook</a>}
            {data?.telegram && <a href={data.telegram} rel="noreferrer" target={'_blank'}>Telegram</a>}
          </Typography>
        </Box>
        <Box className={classes.copyright} display={'flex'} justifyContent={'center'} mt={2.5}>
          <Typography>©<span>2022 Dragonflies Properties, Inc. All Rights Reserved.</span></Typography>
        </Box>
      </Box> :
        <Container maxWidth={false} className={classes.container}>
          <Box className={classes.copyright} display={'flex'} alignItems={'center'}>
            <Box className={classes.imgList} mr={2}>
              <img src={logoData ? logoData.logo : logo} alt="" />
            </Box>
            <Box>
              {logoData && <Typography className={classes.idadaki}>{locale === 'zh-CN' ? logoData.nameZh : locale === 'ja-JP' ? logoData.nameJp : logoData.nameEn}</Typography>}
              <Typography>©<span>2022 Dragonflies Properties, Inc. All Rights Reserved.</span></Typography>
            </Box>
          </Box>
          <div className={classes.terms}>
            <Box>
              <Typography className={classes.links}>
                <a href="/">Terms</a>
                <a href="/">Privacy</a>
                <a href="/">Status</a>
                <a href="/">Help</a>
                <a href="/">Press</a>
                <a href="/">Blog</a>
                <a href="/">Sign Up</a>
              </Typography>
              <Typography className={classes.links}>
                {data?.discord && <a href={data.discord} rel="noreferrer" target={'_blank'}>Discord</a>}
                {data?.twitter && <a href={data.twitter} rel="noreferrer" target={'_blank'}>Twitter</a>}
                {data?.instagram && <a href={data.instagram} rel="noreferrer" target={'_blank'}>Instagram</a>}
                {data?.facebook && <a href={data.facebook} rel="noreferrer" target={'_blank'}>Facebook</a>}
                {data?.telegram && <a href={data.telegram} rel="noreferrer" target={'_blank'}>Telegram</a>}
              </Typography>
            </Box>
            {/* <Box className={classes.imgList} ml={3}>
              <img src={logoData ? logoData.logo : logo} alt="" />
            </Box> */}
          </div>
        </Container>
      }

    </footer>
  );
};
