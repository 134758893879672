
import { SvgIconProps } from '@material-ui/core';

export const Twitter = (props: SvgIconProps) => {
  return (
    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7974 4.04822C18.8101 4.22588 18.8101 4.40357 18.8101 4.58123C18.8101 10 14.6857 16.2437 7.14759 16.2437C4.82524 16.2437 2.66788 15.5711 0.853149 14.4036C1.18311 14.4417 1.50034 14.4544 1.843 14.4544C3.75922 14.4544 5.52321 13.8072 6.93185 12.7031C5.12981 12.665 3.61964 11.4848 3.09933 9.86043C3.35316 9.89848 3.60695 9.92387 3.87347 9.92387C4.24148 9.92387 4.60953 9.87309 4.95215 9.7843C3.07398 9.40355 1.6653 7.75382 1.6653 5.76143V5.71069C2.21097 6.01526 2.84554 6.20561 3.51808 6.23096C2.41401 5.4949 1.69069 4.23857 1.69069 2.81724C1.69069 2.05583 1.8937 1.35786 2.24905 0.748714C4.26683 3.23603 7.29985 4.86037 10.7008 5.03807C10.6374 4.7335 10.5993 4.41627 10.5993 4.099C10.5993 1.84009 12.4267 0 14.6983 0C15.8785 0 16.9445 0.494924 17.6933 1.29442C18.6196 1.11676 19.5079 0.774105 20.2948 0.304572C19.9902 1.25637 19.343 2.05587 18.4928 2.56345C19.3176 2.47466 20.1171 2.24618 20.8531 1.92895C20.2949 2.7411 19.5969 3.46443 18.7974 4.04822Z" fill="white" />
    </svg>

  );
};

