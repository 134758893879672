import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_SWAP = '/dfdunk/swap';

export const DFdunkSwapRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_SWAP,
    generatePath: () => PATH_SWAP,
  },
};

const LoadableDFdunkContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/Swap').then(module => module.Swap),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function DFdunkSwapRoutes() {
  return (
    <Route
      path={DFdunkSwapRoutesConfig.Index.path}
      exact={true}
      component={LoadableDFdunkContainer}
    />
  );
}
