import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_INFEX = '/dfdunk/index';

export const DFdunkRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_INFEX,
    generatePath: () => PATH_INFEX,
  },
};

const LoadableDFdunkContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/Home').then(module => module.Home),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function DFdunkRoutes() {
  return (
    <Route
      path={DFdunkRoutesConfig.Index.path}
      exact={true}
      component={LoadableDFdunkContainer}
    />
  );
}
