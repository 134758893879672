import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_DROPS = '/dfdunk/drops';

export const DFdunkDropsRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_DROPS,
    generatePath: () => PATH_DROPS,
  },
};

const LoadableDFdunkContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/Drops').then(module => module.Drops),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function DFdunkDropsRoutes() {
  return (
    <Route
      path={DFdunkDropsRoutesConfig.Index.path}
      exact={true}
      component={LoadableDFdunkContainer}
    />
  );
}
