import {
  Container, ClickAwayListener, Box, Drawer
  // , Typography 
} from '@material-ui/core';
import { useAccount } from 'modules/account/hooks/useAccount';
import { t } from 'modules/i18n/utils/intl';
import { Button } from 'modules/uiKit/Button';
import { Wallet } from '../Wallet';
import { useHeaderStyles } from './HeaderStyles';
// import { Logo } from 'modules/layout/components/DFduckLogo';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import {
  SelectChainDialog,
  useDialogState,
} from 'modules/layout/components/Header/components/SelectChainDialog';
import { LocaleSwitcher } from 'modules/common/components/LocaleSwitcher';
import { Link } from 'react-router-dom';
import { DFdunkRoutesConfig } from 'modules/DFdunkHome/Routes';
import { getDfdunkLogo, IDfdunkLogoData, getDfdunkTab, IDfdunkTabDate } from 'modules/common/actions/commonRequest';
import { useQuery } from '@redux-requests/react';
import { useLocale } from 'modules/i18n/utils/useLocale';
import { Social } from 'modules/common/components/Social';
import { getDfdunkSns, IDfdunkSnsData } from 'modules/common/actions/commonRequest';
import { useIsSMDown } from 'modules/themes/useTheme';
import { useHeader } from './useHeader';
import { Toggle } from '../Toggle';
import { useWalletDropdown } from '../Wallet/useWalletDropdown';
import { useHistory } from 'react-router-dom';

const lindSpaceStyles = { marginLeft: 10 };

export const Header = () => {
  const { isConnected, handleConnect, loading,
    chainId
  } = useAccount();
  // const dispatchRequest = useDispatchRequest();
  const classes = useHeaderStyles();
  const renderedWallet = <Wallet />;
  const { locale } = useLocale();
  const history = useHistory();
  const {
    opened: openedSelectChainDialog,
    close: closeSelectChainDialog,
  } = useDialogState();
  const { data: logoData } = useQuery<IDfdunkLogoData | null>({
    type: getDfdunkLogo.toString(),
  });
  const { data: tabData } = useQuery<IDfdunkTabDate[] | null>({
    type: getDfdunkTab.toString(),
  });
  const { data: snsData } = useQuery<IDfdunkSnsData | null>({
    type: getDfdunkSns.toString(),
  });
  const isMobile = useIsSMDown();
  const {
    handleDisconnect,
  } = useWalletDropdown();

  const {
    onNavClose,
    onNavOpen,
    onClickAwayNav,
    mobileNavShowed,
  } = useHeader();

  const renderedDesktop = (
    <>
      {!isConnected && (
        <Button onClick={handleConnect} className={classNames(classes.connect, classes.connectBorder)} loading={loading}>
          {t('header.connect')}
        </Button>
      )}
      {isConnected && (
        <div className={classes.connected}>
          {renderedWallet}
        </div>
      )}
    </>
  );
  const renderedMobile = (
    <>
      <div className={classes.buttons}>
        <LocaleSwitcher />
        <ClickAwayListener onClickAway={onClickAwayNav}>
          <div>
            <Toggle
              onClick={mobileNavShowed ? onNavClose : onNavOpen}
              isActive={mobileNavShowed}
            />
            <Box className={classes.drawerContent}>
              <Drawer
                className={classes.drawer}
                ModalProps={{
                  BackdropProps: {
                    classes: {
                      root: classes.drawerBackdrop,
                    },
                  },
                }}
                classes={{
                  paperAnchorRight: classes.drawerPaper,
                }}
                elevation={0}
                anchor="right"
                open={mobileNavShowed}
                onClose={onNavClose}
              >
                <Container className={classes.navInner}>
                  <Box className={classes.btnMobile}>
                    {tabData?.map((item) => {
                      return item.status && <Button
                        // component={NavLink}
                        variant="text"
                        style={lindSpaceStyles}
                        activeClassName={classes.activeLink}
                        className={classes.link}
                        onClick={() => {
                          if (item.link === '/dfdunk/coming_soon') {
                            window.location.href = 'https://hotluuu.io/collection/detail?tab=Items&address=0x07aec8b03f1e0bda03a7e5593b2444104b3e43f0'
                            onNavClose({ event: {} });
                          } else {
                            history.push(item.link);
                            onNavClose({ event: {} });
                          }
                        }}
                        key={item.id}
                      >
                        {locale === 'zh-CN' ? item.nameZh : locale === 'ja-JP' ? item.nameJp : item.nameEn}
                      </Button>
                    })}
                    {isConnected && <Button
                      variant="text"
                      style={lindSpaceStyles}
                      activeClassName={classes.activeLink}
                      className={classes.link}
                      onClick={handleDisconnect}
                    >
                      DISCONNECT
                    </Button>
                    }
                  </Box>
                  <Box ml={-1}>
                    <Social
                      twitter={snsData?.twitter}
                      instagram={snsData?.instagram}
                      telegram={snsData?.telegram}
                      discord={snsData?.discord}
                      facebook={snsData?.facebook}
                    />
                  </Box>
                  {!isConnected && (<>
                    <Button
                      onClick={handleConnect}
                      loading={loading}
                      className={classes.connectMobile}
                    >
                      {t('header.connect')}
                    </Button>
                  </>
                  )}
                  <SelectChainDialog
                    currentChain={chainId}
                    isOpen={openedSelectChainDialog}
                    onClose={closeSelectChainDialog}
                  />
                </Container>
              </Drawer>
            </Box>
          </div>
        </ClickAwayListener>
      </div>
    </>
  );

  return (
    <header className={classNames(classes.root)}>

      <Container className={classes.container} maxWidth={false}>
        <SelectChainDialog
          isOpen={openedSelectChainDialog}
          onClose={closeSelectChainDialog}
          currentChain={chainId}
        />
        <Box className={classes.navs}>
          <Link
            to={DFdunkRoutesConfig.Index.path}
            className={classes.root}
          >
            <img
              alt="DFDunk"
              className={classNames(classes.img, classes.ableDark)}
              src={logoData ? logoData.logo : ''}
            />
            {/* {logoData && <Typography className={classes.idadaki}>{locale === 'zh-CN' ? logoData.nameZh : locale === 'ja-JP' ? logoData.nameJp : logoData.nameEn}</Typography>} */}
          </Link>
          {isMobile && renderedMobile}
          {!isMobile && tabData?.map((item) => {
            return item.status &&
              item.link === '/dfdunk/coming_soon' ? <Button
                // component={NavLink}
                variant="text"
                style={lindSpaceStyles}
                activeClassName={classes.activeLink}
                className={classes.link}
                // to={item.link}
                key={item.id}
                onClick={() => {
                  window.location.href = 'https://hotluuu.io/collection/detail?tab=Items&address=0x07aec8b03f1e0bda03a7e5593b2444104b3e43f0'
                }}
              >
              {locale === 'zh-CN' ? item.nameZh : locale === 'ja-JP' ? item.nameJp : item.nameEn}
            </Button> : <Button
              component={NavLink}
              variant="text"
              style={lindSpaceStyles}
              activeClassName={classes.activeLink}
              className={classes.link}
              to={item.link}
              key={item.id}
            >
              {locale === 'zh-CN' ? item.nameZh : locale === 'ja-JP' ? item.nameJp : item.nameEn}
            </Button>
          })}

        </Box>
        {/* <Box className={classes.centerLogo}>
        
      </Box> */}
        {!isMobile && <Box display={'flex'} alignItems={'center'}>
          <Social
            twitter={snsData?.twitter}
            instagram={snsData?.instagram}
            telegram={snsData?.telegram}
            discord={snsData?.discord}
            facebook={snsData?.facebook}
          />
          <Box mr={1}>
            <LocaleSwitcher />
          </Box>
          {renderedDesktop}
        </Box>}
      </Container>

    </header>
  );
};
