import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_DFDUNK_MINT = '/dfdunk/mint';

export const DFdunkMintRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_DFDUNK_MINT,
    generatePath: () => PATH_DFDUNK_MINT,
  },
};

const LoadableIndexContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/Mint').then(module => module.Mint),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function DFdunkMintRoutes() {
  return (
    <Route
      path={DFdunkMintRoutesConfig.Index.path}
      exact={true}
      component={LoadableIndexContainer}
    />
  );
}
