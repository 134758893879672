import { makeStyles, Theme } from '@material-ui/core';

export const useSocialStyles = makeStyles<Theme>(theme => ({
  list: {
    display: 'flex',
    margin: 0,
    padding: theme.spacing(1, 2),
    listStyle: 'none',
    height: '28px',
    borderRadius: '4px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },

  item: {
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0 6px',
    },
  },

  icon: {
    padding: 0,
    '& svg': {
      width: '20px'
    }
  },
}));
