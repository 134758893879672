import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { generatePath, Route, useParams } from 'react-router-dom';

const PATH_HISTORY_DETAIL = '/dfdunk/history/detail/:historyId';

export const DFdunkHistoryDetailRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_HISTORY_DETAIL,
    generatePath: (historyId: string) =>
      generatePath(PATH_HISTORY_DETAIL, { historyId }),
    useParams: () => {
      const { historyId } = useParams<{ historyId: string }>();
      return {
        historyId,
      };
    },
  },
};

const LoadableDFdunkContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/HistoryDetail').then(module => module.HistoryDetail),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function DFdunkHistoryDetailRoutes() {
  return (
    <Route
      path={DFdunkHistoryDetailRoutesConfig.Index.path}
      exact={true}
      component={LoadableDFdunkContainer}
    />
  );
}
